import turtle from './turtle.gif';
import React, { useEffect } from 'react';
import './App.css';
import ScaleText from "react-scale-text";

function App() {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <div className="App">
      <div className="relative-container">
      <div className="App-header">
            <p>quang</p>
      </div>
      <div className="App-header-legend">
            <p>designed by</p>
      </div>
      </div>
    </div>
  );
}

export default App;
